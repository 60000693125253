
import axios from '../api/newAxiosProxy'


export function upload(contentType, afterSelectFileFn) {
    let input = document.createElement('input')
    input.type = 'file'
    input.accept = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
    input.click()
    const data = new FormData()
    return new Promise((resolve, reject) => {
        input.onchange = () => {
            let file = input.files[0];
            let fileType = file.name.substring(file.name.lastIndexOf('.') + 1)
            console.log(fileType)
            if (!/(xlsx|xls)/.test(fileType)) {
                reject('只能选择.xlsx或.xls类型的文件')
                return;
            }
            data.set('file', file)
            if (afterSelectFileFn) {
                afterSelectFileFn()
            }
            axios({
                method: 'post',
                url: '/changeLevel/importExcel',
                params: { contentType },
                data
            }).then(res => {
                resolve(res||'上传成功')
            }).catch(err => {
                reject(err||'上传失败')
            })
        }
    })
}


export function download(operationId, fileName) {
    return axios({
        method: 'get',
        url: '/changeLevel/export',
        responseType: 'blob',
        params: { operationId }
    }).then(blob => {
        let link = document.createElement('a');
        let url = URL.createObjectURL(blob);
        link.href = url;
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(url);
    })
}

/**
 * status 0-处理中，1-已完成
 * @param {{pageSize,pageNo,startTime,endTime,status}} params 
 */
export function getList(params) {
    return axios({
        method: 'get',
        url: `/changeLevel/queryPageByTime/${params.pageNo}/${params.pageSize}`,
        params
    }).then(res => {
        res.rows = res.rows || []
        return res;
    })
}