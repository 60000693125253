<template>
  <ListFrame
    :getPageFn="loadData"
    :viewConf="{showReorder: false, }"
    ref="frame"
    :customedParams="customedParams"
  >
    <template #conditions="{ requestFn }">
      <DatePicker
        :editable="false"
        @on-change="changeTimeRange"
        @on-open-change="timeOpenChange"
        class="m-r-md"
        type="datetimerange"
        format="yyyy-MM-dd HH:mm"
        placeholder="选择时间范围"
        style="width: 300px"
      />
      <div class="flex1"></div>
      <Poptip placement="bottom" ref="poptip">
        <Button type="primary">文章调级导入</Button>
        <template slot="content">
          选择导入的类型：
          <div class="pop-wrapper">
            <Button
              v-for="type of contentTypes"
              :key="type"
              type="primary"
              @click="upload(type)"
            >{{ type | typePipe}}</Button>
          </div>
        </template>
      </Poptip>
      <div class="grid-item m-l-md p-t-md m-b-sm">
        <span>文件名</span>
        <span>时间</span>
        <span>状态</span>
      </div>
    </template>
    <template v-slot="{item}">
      <div class="grid-item">
        <span>{{item.fileName}}</span>
        <Time :time="item.createTime" type="datetime" />
        <span>{{{0:'处理中',1:'已完成'}[item.status]}}</span>
        <Button type="text" class="download" @click="download(item,$event)">
          <Icon type="md-download" />
        </Button>
      </div>
    </template>
  </ListFrame>
</template>

<script>
import { getList, download, upload } from "./service";
const contentTypes = [
  "enSentence",
  "enVerse",
  "enView",
  "enWords",
  "cnWords",
  "cnView",
  "cnVerse"
];
export default {
  data() {
    return {
      customedParams: {
        startTime: null,
        endTime: null
      },
      timeRange: ["", ""]
    };
  },
  computed: {
    laun() {
      return this.$store.getters.getLaun;
    },
    contentTypes() {
      return contentTypes.filter(ele => ele.indexOf(this.laun) >= 0);
    }
  },
  methods: {
    loadData(params) {
      return getList(params);
    },
    download(item, e) {
      /**
       * @type { HTMLButtonElement}
       */
      const btn = e.target.parentElement.parentElement;

      btn.disabled = true;
      download(item.id, item.fileName).finally(() => {
        btn.disabled = false;
      });
    },
    upload(contentType) {
      this.$refs.poptip.cancel();
      upload(contentType, () => {
        this.$Spin.show();
      })
        .then(res => {
          this.$Message.success(res);
          this.$refs.frame.refresh();
        })
        .catch(err => {
          this.$Message.warning(err);
        })
        .finally(() => {
          this.$Spin.hide();
        });
    },
    changeTimeRange([time1, time2]) {
      this.timeRange = [time1, time2];
    },
    timeOpenChange(toOpen) {
      if (toOpen) {
        return;
      }
      let time1 = this.timeRange[0] ? this.timeRange[0] + ":00" : "";
      let time2 = this.timeRange[1] ? this.timeRange[1] + ":00" : "";
      if (
        this.customedParams.startTime === time1 &&
        this.customedParams.endTime === time2
      ) {
        return;
      }

      this.customedParams.startTime = time1;
      this.customedParams.endTime = time2;
      this.$refs.frame.refresh();
    }
  }
};
</script>

<style scoped>
.grid-item {
  display: grid;
  grid-template-columns: 35% minmax(10em, 30%) minmax(3em, 15%) 1fr;
  width: 100%;
}
.download {
  font-size: 20px;
  box-shadow: none !important;
  justify-self: start;
}
.download:hover {
  background: #f3f9ff;
}
.pop-wrapper {
  display: grid;
  grid-template-columns: repeat(3, minmax(116px, 1fr));
}
.pop-wrapper > * {
  margin: 1em;
}
</style>